<template>
    <div class="home_marriage_test_payment" id="agentTitle">
        <header>
            <!-- <h1><img src="http://cdn.zhiyileiju.cn/home_marriage_test_payment_h1.jpg " alt=""></h1> -->
           
                        <div class="bottom_box">
                            <!-- <p>亲爱的<span>{{name}}：</span></p> -->
                            <p>亲爱的{{name}}：</p>
                            <p>从测算结果来看，<span>2022年底</span>你的姻缘将会出现特别重要的<span>婚姻感情转机</span>！</p>
                        </div>
        </header>
        <section>
            <div class="top_box__">
                <div class="card_box">
                    <div class="card_top_box">
                        <h1>  {{name}}的婚姻测算结果</h1>
                    </div>
                    <img src="http://cdn.zhiyileiju.cn/title_left_flower.jpg" alt="" class="img_icom_left">
                    <img src="http://cdn.zhiyileiju.cn/title_right_flower.jpg" alt="" class="img_icom_right">
                    <p class="top_p__">性别：{{userinfo.sex}} <span>公历：{{userinfo.time_of_birth}}</span></p>
                    <div class="card_content_box">
                        <h2> <span>限时优惠￥{{price.marriage_amount}}元</span>  <i>距优惠结束</i> </h2>
                        <p style="border-bottom:1px solid rgba(202, 166, 146,0.5);padding-bottom:.28rem"><i>原价￥{{price.fake_amount}}元</i> <span><van-count-down @change="timeTrigger" millisecond :time="time" format="HH:mm:ss:SS" style="color:#C91C18"/></span></p>
                        <h3><i>含一生婚姻情况，脱单或复合机会，真爱性格</i></h3>
                        <div v-if="!showwechat" class="buttom_box" style="background: #04C967;" @click="paymentButtom('wechat')">
                            <p> <img src="http://cdn.zhiyileiju.cn/wechaticon111.png" alt=""> 微信支付</p>
                        </div>
                        <div class="buttom_box" style="background: #1B95D4;margin-top:.3rem;" @click="paymentButtom('alipay')" v-if="!this.is_weixn">
                            <p> <img src="http://cdn.zhiyileiju.cn/alipayicon111.png" alt=""> 支付宝</p>
                        </div>
                        <p class="bottom_p"><i>已有<strong>9, 789, 316</strong> 人查看结果， <strong>99.7%</strong>觉得对婚姻有帮助</i></p>
                        <div class="icon_brand">
                            <img src="http://cdn.zhiyileiju.cn/baozhang.png" alt="">
                        </div>
                        <p class="bottom_p_icon_brand">支付系统已通过安全联盟认证，答案仅自己可见</p>
                    </div>
                </div>
                <h3 style="margin-top: -0.4rem;color:#DF5656;">支付完成后您将看到以下内容</h3>
                <p class="title_p__">资深专家团队倾力打造</p>
                <div class="card_box">
                    <div class="card_top_box">
                        <h1>您的个人婚姻是怎样的</h1>
                    </div>
                    <img src="http://cdn.zhiyileiju.cn/title_left_flower.jpg" alt="" class="img_icom_left">
                    <img src="http://cdn.zhiyileiju.cn/title_right_flower.jpg" alt="" class="img_icom_right">
                    <div class="text_dim_box">
                        <p>
                            分类：到哦分红二十年后飞机场i欧凡参加覅色戒出来看而快乐五：十分你来南非而窝囊废就能发顺  丰单号呢发货偶
                            尔发货合肥和
                        </p>
                        <p>分类：到i：哦分红二十年后飞机场i欧凡参加覅色戒出来看
                            而快乐五十分你来南非而窝囊废就能发顺  丰单号呢发货
                            分类：到i哦分红二十年后飞机场i欧凡参加覅色戒出来看而
                            快乐五：十分你来南非而窝囊废就能发顺  丰单号呢发货偶
                            尔发货合肥和.
                        </p>
                        <p>
                            而快乐五十分你来南非而窝囊废就能发顺  丰单号呢发货是
                            而快乐五十分你来南非而窝囊废就能发顺  丰单号呢发货是
                            尔发货合肥和.分类：到i哦分红二十年后飞机场i戒出来看而
                            快乐五：十分你来南非而窝囊废就能发顺  丰单号呢发货
                        </p>
                    </div>
                    <div class="suspend_text_box">
                        <p style="margin-top: .5rem;">1、你是适合早婚还是晚婚</p>
                        <p>2、你未来的感情幸福还是痛苦</p>
                        <p>3、哪些年份容易遇到对的人</p>
                    </div>
                   <div class="buttom_box buttom_box_text__ " style="background: #C91C18;" @click="backToTheTopCallback">
                        <p> <img src="http://cdn.zhiyileiju.cn/1421633941854_.pic_hd.jpg" alt="">  立即解锁查看</p>
                    </div>
                </div>
                <div class="card_box">
                    <div class="card_top_box">
                        <h1>您的另一半长什么样</h1>
                    </div>
                    <img src="http://cdn.zhiyileiju.cn/title_left_flower.jpg" alt="" class="img_icom_left">
                    <img src="http://cdn.zhiyileiju.cn/title_right_flower.jpg" alt="" class="img_icom_right">
                    <div class="text_dim_box">
                        <p>
                            分类：到哦分红二十年后飞机场i欧凡参加覅色戒出来看而快乐五：十分你来南非而窝囊废就能发顺  丰单号呢发货偶
                            尔发货合肥和
                        </p>
                        <p>分类：到i：哦分红二十年后飞机场i欧凡参加覅色戒出来看
                            而快乐五十分你来南非而窝囊废就能发顺  丰单号呢发货
                            分类：到i哦分红二十年后飞机场i欧凡参加覅色戒出来看而
                            快乐五：十分你来南非而窝囊废就能发顺  丰单号呢发货偶
                            尔发货合肥和.
                        </p>
                        <p>
                            而快乐五十分你来南非而窝囊废就能发顺  丰单号呢发货是
                            而快乐五十分你来南非而窝囊废就能发顺  丰单号呢发货是
                            尔发货合肥和.分类：到i哦分红二十年后飞机场i戒出来看而
                            快乐五：十分你来南非而窝囊废就能发顺  丰单号呢发货
                        </p>
                    </div>
                    <div class="suspend_text_box">
                        <p style="margin-top: .5rem;">1、Ta是高富帅/白富美吗</p>
                        <p>2、Ta的性格和为人怎样</p>
                        <p>3、在哪里能遇到真正爱你的人</p>
                    </div>
                   <div class="buttom_box buttom_box_text__" style="background: #C91C18;" @click="backToTheTopCallback">
                        <p> <img src="http://cdn.zhiyileiju.cn/1421633941854_.pic_hd.jpg" alt=""> 立即解锁查看</p>
                    </div>
                </div>
                <div class="card_box">
                    <div class="card_top_box">
                        <h1>您性格对婚姻的影响</h1>
                    </div>
                    <img src="http://cdn.zhiyileiju.cn/title_left_flower.jpg" alt="" class="img_icom_left">
                    <img src="http://cdn.zhiyileiju.cn/title_right_flower.jpg" alt="" class="img_icom_right">
                    <div class="text_dim_box">
                        <p>
                            分类：到哦分红二十年后飞机场i欧凡参加覅色戒出来看而快乐五：十分你来南非而窝囊废就能发顺  丰单号呢发货偶
                            尔发货合肥和
                        </p>
                        <p>分类：到i：哦分红二十年后飞机场i欧凡参加覅色戒出来看
                            而快乐五十分你来南非而窝囊废就能发顺  丰单号呢发货
                            分类：到i哦分红二十年后飞机场i欧凡参加覅色戒出来看而
                            快乐五：十分你来南非而窝囊废就能发顺  丰单号呢发货偶
                            尔发货合肥和.
                        </p>
                        <p>
                            而快乐五十分你来南非而窝囊废就能发顺  丰单号呢发货是
                            而快乐五十分你来南非而窝囊废就能发顺  丰单号呢发货是
                            尔发货合肥和.分类：到i哦分红二十年后飞机场i戒出来看而
                            快乐五：十分你来南非而窝囊废就能发顺  丰单号呢发货
                        </p>
                    </div>
                    <div class="suspend_text_box">
                        <p style="margin-top: .5rem;">1、你的基本性格和爱情观念</p>
                        <p>2、导致你单身或感情不稳定的原因</p>
                        <p>3、做出哪些改变可快速脱单或复合</p>
                    </div>
                   <div class="buttom_box buttom_box_text__" style="background: #C91C18;" @click="backToTheTopCallback">
                        <p> <img src="http://cdn.zhiyileiju.cn/1421633941854_.pic_hd.jpg" alt="">  立即解锁查看</p>
                    </div>
                </div>
                <div class="card_box">
                    <div class="card_top_box">
                        <h1>您一生会有几段婚姻</h1>
                    </div>
                    <img src="http://cdn.zhiyileiju.cn/title_left_flower.jpg" alt="" class="img_icom_left">
                    <img src="http://cdn.zhiyileiju.cn/title_right_flower.jpg" alt="" class="img_icom_right">
                    <div class="text_dim_box">
                        <p>
                            分类：到哦分红二十年后飞机场i欧凡参加覅色戒出来看而快乐五：十分你来南非而窝囊废就能发顺  丰单号呢发货偶
                            尔发货合肥和
                        </p>
                        <p>分类：到i：哦分红二十年后飞机场i欧凡参加覅色戒出来看
                            而快乐五十分你来南非而窝囊废就能发顺  丰单号呢发货
                            分类：到i哦分红二十年后飞机场i欧凡参加覅色戒出来看而
                            快乐五：十分你来南非而窝囊废就能发顺  丰单号呢发货偶
                            尔发货合肥和.
                        </p>
                        <p>
                            而快乐五十分你来南非而窝囊废就能发顺  丰单号呢发货是
                            而快乐五十分你来南非而窝囊废就能发顺  丰单号呢发货是
                            尔发货合肥和.分类：到i哦分红二十年后飞机场i戒出来看而
                            快乐五：十分你来南非而窝囊废就能发顺  丰单号呢发货
                        </p>
                    </div>
                    <div class="suspend_text_box">
                        <p style="margin-top: .5rem;">1、你会有多少段感情经历</p>
                        <p>2、什么样的感情你能走到最后</p>
                        <p>3、会遇到烂桃花吗</p>
                    </div>
                   <div class="buttom_box buttom_box_text__" style="background: #C91C18;" @click="backToTheTopCallback">
                        <p> <img src="http://cdn.zhiyileiju.cn/1421633941854_.pic_hd.jpg" alt="">  立即解锁查看</p>
                    </div>
                </div>
                <div class="card_box">
                    <div class="card_top_box">
                        <h1>如何稳定婚姻中的关系</h1>
                    </div>
                    <img src="http://cdn.zhiyileiju.cn/title_left_flower.jpg" alt="" class="img_icom_left">
                    <img src="http://cdn.zhiyileiju.cn/title_right_flower.jpg" alt="" class="img_icom_right">
                    <div class="text_dim_box">
                        <p>
                            分类：到哦分红二十年后飞机场i欧凡参加覅色戒出来看而快乐五：十分你来南非而窝囊废就能发顺  丰单号呢发货偶
                            尔发货合肥和
                        </p>
                        <p>分类：到i：哦分红二十年后飞机场i欧凡参加覅色戒出来看
                            而快乐五十分你来南非而窝囊废就能发顺  丰单号呢发货
                            分类：到i哦分红二十年后飞机场i欧凡参加覅色戒出来看而
                            快乐五：十分你来南非而窝囊废就能发顺  丰单号呢发货偶
                            尔发货合肥和.
                        </p>
                        <p>
                            而快乐五十分你来南非而窝囊废就能发顺  丰单号呢发货是
                            而快乐五十分你来南非而窝囊废就能发顺  丰单号呢发货是
                            尔发货合肥和.分类：到i哦分红二十年后飞机场i戒出来看而
                            快乐五：十分你来南非而窝囊废就能发顺  丰单号呢发货
                        </p>
                    </div>
                    <div class="suspend_text_box">
                        <p style="margin-top: .5rem;">1、如何与另一半相处</p>
                        <p>2、自身有什么缺点需要注意</p>
                    </div>
                   <div class="buttom_box buttom_box_text__" style="background: #C91C18;" @click="backToTheTopCallback">
                        <p> <img src="http://cdn.zhiyileiju.cn/1421633941854_.pic_hd.jpg" alt="">  立即解锁查看</p>
                    </div>
                </div>
                <div class="card_box">
                    <div class="card_top_box">
                        <h1>最适合您的婚配建议</h1>
                    </div>
                    <img src="http://cdn.zhiyileiju.cn/title_left_flower.jpg" alt="" class="img_icom_left">
                    <img src="http://cdn.zhiyileiju.cn/title_right_flower.jpg" alt="" class="img_icom_right">
                    <div class="text_dim_box">
                        <p>
                            分类：到哦分红二十年后飞机场i欧凡参加覅色戒出来看而快乐五：十分你来南非而窝囊废就能发顺  丰单号呢发货偶
                            尔发货合肥和
                        </p>
                        <p>分类：到i：哦分红二十年后飞机场i欧凡参加覅色戒出来看
                            而快乐五十分你来南非而窝囊废就能发顺  丰单号呢发货
                            分类：到i哦分红二十年后飞机场i欧凡参加覅色戒出来看而
                            快乐五：十分你来南非而窝囊废就能发顺  丰单号呢发货偶
                            尔发货合肥和.
                        </p>
                        <p>
                            而快乐五十分你来南非而窝囊废就能发顺  丰单号呢发货是
                            而快乐五十分你来南非而窝囊废就能发顺  丰单号呢发货是
                            尔发货合肥和.分类：到i哦分红二十年后飞机场i戒出来看而
                            快乐五：十分你来南非而窝囊废就能发顺  丰单号呢发货
                        </p>
                    </div>
                    <div class="suspend_text_box">
                        <p style="margin-top: .5rem;">1、能跟您长相厮守的人特征</p>
                        <p>2、什么时候更适合结婚</p>
                        <p>3、做出什么改变婚姻能更幸福</p>
                    </div>
                   <div class="buttom_box buttom_box_text__" style="background: #C91C18;" @click="backToTheTopCallback">
                        <p> <img src="http://cdn.zhiyileiju.cn/1421633941854_.pic_hd.jpg" alt="">  立即解锁查看</p>
                    </div>
                </div>
                <div class="card_box">
                    <div class="card_top_box">
                        <h1>追爱攻略和幸福攻略</h1>
                    </div>
                    <img src="http://cdn.zhiyileiju.cn/title_left_flower.jpg" alt="" class="img_icom_left">
                    <img src="http://cdn.zhiyileiju.cn/title_right_flower.jpg" alt="" class="img_icom_right">
                    <div class="text_dim_box">
                        <p>
                            分类：到哦分红二十年后飞机场i欧凡参加覅色戒出来看而快乐五：十分你来南非而窝囊废就能发顺  丰单号呢发货偶
                            尔发货合肥和
                        </p>
                        <p>分类：到i：哦分红二十年后飞机场i欧凡参加覅色戒出来看
                            而快乐五十分你来南非而窝囊废就能发顺  丰单号呢发货
                            分类：到i哦分红二十年后飞机场i欧凡参加覅色戒出来看而
                            快乐五：十分你来南非而窝囊废就能发顺  丰单号呢发货偶
                            尔发货合肥和.
                        </p>
                        <p>
                            而快乐五十分你来南非而窝囊废就能发顺  丰单号呢发货是
                            而快乐五十分你来南非而窝囊废就能发顺  丰单号呢发货是
                            尔发货合肥和.分类：到i哦分红二十年后飞机场i戒出来看而
                            快乐五：十分你来南非而窝囊废就能发顺  丰单号呢发货
                        </p>
                    </div>
                    <div class="suspend_text_box">
                        <p style="margin-top: .5rem;">1、如何增加好桃花，脱单攻略</p>
                        <p>2、如何把握住幸福，复合办法</p>
                        <p>3、提升婚姻质量、感情甜蜜指数</p>
                    </div>
                   <div class="buttom_box buttom_box_text__" style="background: #C91C18;" @click="backToTheTopCallback">
                        <p> <img src="http://cdn.zhiyileiju.cn/1421633941854_.pic_hd.jpg" alt="">  立即解锁查看 </p>
                    </div>
                </div>
                <div class="card_box">
                    <div class="card_top_box">
                        <h1>好评如潮</h1>
                    </div>
                    <img src="http://cdn.zhiyileiju.cn/title_left_flower.jpg" alt="" class="img_icom_left">
                    <img src="http://cdn.zhiyileiju.cn/title_right_flower.jpg" alt="" class="img_icom_right">
                    <Comments />
                    <p style="text-align:center;margin-bottom: .3rem;">最新支付订单</p>
                    <!-- <orderscroll :prices='price'/> -->
                    <orderscroll :prices='price'> </orderscroll>
                </div>
            </div>
            <h4 class="bottom_title">资深专家团队倾力打造</h4>
        </section>
        <footer>
            <div class="top_brand">
                <div class="img_box" style="width: 1.56rem"><img src="http://cdn.zhiyileiju.cn/home_marriage_test_brand_3.jpg" alt=""></div>
                <div class="img_box" style="width: 1.7rem"><img src="http://cdn.zhiyileiju.cn/home_marriage_test_brand_2.jpg" alt=""></div>
                <div class="img_box" style="width: 2.51rem"><img src="http://cdn.zhiyileiju.cn/home_marriage_test_brand_1.jpg" alt=""></div>
            </div>
            <div class="buttom_anchor" v-if="fixedbtn" @click="backToTheTopCallback('anchor')">
                付费解锁全部内容
            </div>
        </footer>
        <van-overlay :show="shopPayConfirm" z-index="10" lock-scroll>
            <div class="wrapper center" @click.stop>
                 <div class="block">
                    <div class="title">距婚姻解析只差1步<br></div>
                    <div class="title title_tow">支付后查看婚姻运势详批</div>
                    <div class="bg_box_chunk_">
                        <span class="only_remain_text">仅剩{{percentage_num}}%</span>
                        <div class="img_bg_box"></div>
                        <div class="bottom_text_box__">
                            <span class="bottom_text__">信息提交</span>
                            <span class="bottom_text__">生成报告</span>
                            <span class="bottom_text__">查看婚姻详批</span>
                        </div>
                    </div>
                    <div class="payed payment_" @click="paymentButtoms('')"><img src="http://cdn.zhiyileiju.cn/WechatIMG600.jpeg" alt=""></div>
                    <div class="payed unpay_" @click="unpay">{{popup_text}}</div>
                    <div class="payed  Dcheckorder_" @click="Dcheckorder">已完成支付</div>
                    <div class="count_down_box_">
                         <van-count-down :time="counttime" millisecond>
                            <template #default="timeData">
                                <span class="block_">{{ timeData.hours }}</span>
                                <span class="colon">:</span>
                                <span class="block_">{{ timeData.minutes }}</span>
                                <span class="colon">:</span>
                                <span class="block_">{{ timeData.seconds }}</span>
                                <span class="colon">:</span>
                                <span class="block_">{{ parseInt(timeData.milliseconds / 10) }}</span>
                                <span class="colon text">后失效</span>
                            </template>	
                        </van-count-down>
                    </div>
                </div>
            </div>
        </van-overlay>
        <!--  投诉 -->
        <span  class="fixation_complaint" v-if="complaint_buttom_fal" @click="complaintButtom">
            <img src="https://cdn.zhiyileiju.cn/WechatIMG559.pic" alt="">
        </span>
    </div>
</template>

<script>
import Comments from "../../components/Comment.vue";
import orderscroll from "../../components/OrderScroll.vue";
import payment from "../../mixin/homeMarriageTest/payment"

export default {
    mixins:[payment],
   components: {
       Comments,orderscroll
   },
    
    
}
</script>

<style lang="scss" scoped>
.home_marriage_test_payment{
    background: #fff8f8;
    // position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    header{
       height: 11.13rem;   
       background: url('http://cdn.zhiyileiju.cn/WechatIMG565.jpeg') ; 
       background-size: 100% 100%;
       padding-top: .6rem;
       
        h1{
            text-align: center;
            img{
                width: 4.6rem;
            }
        }
        h2{
            text-align: center;
            font-family: "MicrosoftYaHei";
            color: #FCAF93;
            font-size: .22rem;
            font-weight: 400;
            margin-top: .8rem;
        }
        .bottom_box{
            width: 6.65rem;
            height: 1.59rem;
            background: #fff;
            border-radius: .2rem;
            margin: auto;
            margin-top: 2rem;
            overflow: hidden;
            border: 1px solid #FE6060;
            p{
                color: #525252;
                font-size: .26rem;
                padding: 0 .57rem;
                span{
                    color: #A90017;
                }
            }
            p:first-child{
                margin-top: .22rem;
                margin-bottom: .1rem;
            }
        } 
    }
    section{
        margin-top: -1.3rem;
        .top_box__{
            width: 100%;
            margin-top: -6.9rem;
            .card_box{
                position: relative;
                width: 7.08rem;
                // height: 9.02rem;
                background: #FFF0D3;;
                border:.1rem solid #DC5744;
                border-radius: .3rem;
                margin: 0 auto;
                padding-bottom: .5rem;
                margin-bottom: .8rem;
                .card_top_box{
                    position: relative;
                    top: -0.54rem;
                    width: 5.54rem;
                    height: 1.08rem;
                    margin: 0 auto;
                    background: url("http://cdn.zhiyileiju.cn/title_bg.jpg ");
                    background-size: 100% 100%;
                    // margin-bottom:1rem;
                    h1{
                        font-size: .36rem;
                        text-align: center;
                        line-height: 1.08rem;
                        color: #fecc6d;
                    }
                }
                .img_icom_left{
                    width: .6rem;
                    position: absolute;
                    top: -.18rem;
                    left: -0.05rem;
                }
                .img_icom_right{
                    width: .6rem;
                    position: absolute;
                    top: -.18rem;
                    right: -0.05rem;
                }
                p{
                    font-size: .28rem;
                    color: #A90017;
                    // text-align: center;
                    span{
                        margin-left: .28rem;
                    }
                    // border-bottom: 1px solid #FCAF93;
                }
                .top_p__{
                    background: #F8CAA6;
                    height: .87rem;
                    line-height: .87rem;
                    margin: 0 .32rem;
                    border-radius: .1rem;
                    text-indent: 1em;
                }
                .card_content_box{
                    margin-top: .34rem;
                    padding:0 .32rem;
                    i{
                        font-size: .26rem;
                        color: #C98578;
                    }
                    h2{
                        position: relative;
                        height: .63rem;
                        font-weight: 500;
                        font-size: .36rem;
                        color: #A80009;
                        span{
                            display: inline-block;
                            height: .63rem;
                            background: #C91C18;
                            border-radius: .1rem;
                            padding: 0 .23rem;
                            line-height: .63rem;
                            color: #FFFFFF;
                        }
                        i{
                            position: absolute;
                            right: 0;
                            bottom: 0;
                        }
                    }
                    p{
                        position: relative;
                        border-bottom: none;
                        padding-bottom: 0;
                        text-align: left;
                        i{
                            text-decoration:line-through ;
                        }
                        span{
                            position: absolute;
                            right: 0;
                        }
                    }
                    h3{
                        margin-top:.3rem;
                        font-weight: 400;
                        text-align: center;
                        i{
                            font-size: .28rem;
                        }
                    }
                    .buttom_box{
                        width: 6.41rem;
                        height: .95rem;
                        border-radius: .95rem;
                        cursor:pointer;
                        margin-top:.47rem;
                        p{
                            position: relative;
                            text-align: center;
                            line-height: .95rem;
                            font-size: .36rem;
                            color: #FDFDFD;
                            padding-left: 1rem;
                            img{
                                position: absolute;
                                width: .70rem;
                                top: .12rem;
                                left: 1.8rem;       
                            }
                        }
                    }
                    .bottom_p{
                        text-align: center;
                        margin-top: .28rem;
                        i{
                            text-decoration:none ;
                            strong{
                                color: #a90017;
                                font-weight: 600;
                            }
                        }
                    }
                    .bottom_p_icon_brand{
                        text-align: center;
                        font-size: .24rem;
                        color: #19AA0F;
                    }
                }
                .text_dim_box{
                    margin-top: .16rem;
                    p{
                        padding: 0 .2rem;
                        color: transparent;
                        text-shadow: 2px 2px 3px #000;
                        font-size: .24rem;
                        text-align: left;
                        border-bottom: none;
                        line-height:.4rem;
                        letter-spacing:1px;
                    }
                }
                .suspend_text_box{
                    position: absolute;
                    top: 3rem;
                    left: 50%;
                    background: #fff;
                    transform: translate(-50%,-50%);
                    width: 5.65rem;
                    // height: 2.66rem;
                    padding-bottom: .5rem;
                    border-radius: .1rem;
                    p{
                        text-align: left;
                        border-bottom: none;
                        font-size: .28rem;
                        color: #323232;
                        margin-left: .5rem;
                        line-height: .6rem;
                    }
                }
                .buttom_box{
                    // position: absolute;
                    // top: 4.85rem;;
                    // left: 0;
                    // right: 0;
                    width: 5.69rem;
                    height: .91rem;
                    margin: auto;
                    border-radius: .91rem;
                    p{
                        position: relative;
                        text-align: center;
                        border:none;
                        line-height: .91rem;
                        color: #FFF0D3;
                        font-size: .36rem;
                        img{
                            position: absolute;
                            top: 30%;
                            left: 22%;
                            width: .36rem;
                            // margin-top: .3rem;
                        }
                        
                    }
                }
                .buttom_box_text__{
                    position: absolute;
                    top: 4.85rem;;
                    left: 0;
                    right: 0;
                    p{
                        text-indent: 1em;
                    }
                }
            }
            h3{
                margin-top: .3rem;
                text-align: center;
                color: #FFF0D3;
                font-size: .32rem;
                font-weight: 400;
            }
            .title_p__{
                margin-top: .2rem;
                margin-bottom: .8rem;
                text-align: center;
                font-size:.28rem ;
                color: #E68585;
            }
           
        }
        .bottom_title{
            font-size: .4rem;
            font-weight: 400;
            color: #DF5656;
            text-align: center;
        }
    }
    footer{
        padding-bottom: 1.2rem;
        .top_brand{
            text-align: center;
            .img_box{
                display: inline-block;
                margin-right: .2rem;
            }
            .img_box:last-child{
                margin-right: 0;
            }
        }
        .buttom_anchor{
            position: fixed;
            bottom: 0;
            width: 7.5rem;
            text-align: center;
            line-height: 1.11rem;
            font-size: .36rem;
            background: #E15757;
            box-shadow: 0px -2px 21px -7px rgba(154, 2, 23, 0.45);
            color: #FEFBFB;
            height: 1.11rem;
            animation-name: myfirst;
            animation-duration: 1s;
            animation-iteration-count:infinite;
            -webkit-animation-iteration-count:infinite; /* Safari 和 Chrome */
        }
        @keyframes myfirst
         {
             0%   {transform:scale(1);}
             50%  {transform:scale(1.1)}
             100% {transform:scale(1)}
         }
    }
    .wrapper{
       width: 7.5rem;
       height: 100%;
       margin: 0 auto;
        .block {
            position: fixed;
            top:50%;
            left: 50%;
            margin: 0 auto;
            width: 5.5rem;
            height: 9.3rem;
            transform: translate(-50%,-50%);
            background-color: white;
            border-radius: .4rem;
            padding: 0.4rem 0;
            .title{
                color: #CF031A;
                text-align: center;
                font-size: .352rem;
                font-weight: bold;
                margin: 0;
                margin-top: .1rem;
            }
            .title_tow{
                margin-top: .2rem;
            }
            .bg_box_chunk_{
                overflow: hidden;
                margin-top: .9rem;
                .img_bg_box{
                    width: 4.48rem ;
                    height: .5rem ;
                    background:url('https://cdn.zhiyileiju.cn/WechatIMG599.pic') no-repeat ;
                    background-size: 100% 100%;
                    margin: 0 auto;
                    margin-top: 0.13rem;
                }
                .only_remain_text{
                    float: right;
                    font-size: .26rem;
                    color: #F5A625;
                    margin-right: .21rem;
                }
                .bottom_text_box__{
                    // position: relative;
                    overflow: hidden;
                    width: 5.5rem;
                    white-space : nowrap;
                    .bottom_text__{
                        // position: absolute;
                        float: left;
                        color: #525252;
                        font-size: .26rem;
                        margin-top: .29rem;
                    }
                    .bottom_text__:nth-child(1){
                        margin-left: .26rem;
                    }
                    .bottom_text__:nth-child(2){
                        margin-left: 1.02rem;
                    }
                   
                    .bottom_text__:nth-child(3){
                        margin-left: .4rem;
                    }
                    @media screen and (max-width: 340px) {
                        .bottom_text__:nth-child(3){
                            margin-left: .2rem;
                        }
                    }
                }
            }
            .payed{
                width: 4.5rem;
                height: .98rem;
                font-size: .38rem;
                border-radius: 49px;
                text-align: center;
                margin: 0 auto;
                line-height: .98rem;
            }
            .payment_{
                margin-top: 1.09rem;
                color: #fff;
                background: #CF031A;
            }
            .unpay_{
                margin-top: .31rem;
                color: #fff;
                background: #F5A625;
            }
            .Dcheckorder_{
                margin-top: .31rem;
                box-sizing: border-box;
                border: 1px solid #F5A625;
            }
            .count_down_box_{
                text-align: center;
                margin-top: .4rem;
                .colon {
                    display: inline-block;
                    margin: 0 .04rem;
                    font-size: .2rem;
                    color: #b63437;
                }
                .block_ {
                    display: inline-block;
                    width: .43rem;
                    color: #fff;
                    font-size: .24rem;
                    text-align: center;
                    background-color: #b63437;
                    box-sizing:border-box;
                    border: 1px solid #fa455f;
                }
                .text{
                    margin-left: .1rem;
                    font-size: .14rem;
                    color: #525252;
                }   
            }
        }
    }
     //投诉
        .fixation_complaint{
            position: fixed;
            width: .48rem;
            top: 3.02rem;
            right: 0;
            z-index: 10;
        }
}
</style>
<style lang="scss">
    .home_marriage_test_payment{
        .van-popup{
            background: none;
        }
    }
</style>
